import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../api-config';

const ArticleDetail = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`${API_URL}/users-api/news/${id}`);
        setArticle(response.data);
      } catch (error) {
        console.error('Error fetching article:', error);
        setError(
          error.response?.data?.message || 'No se pudo obtener el artículo. Por favor, inténtalo más tarde.'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return <div className="text-center text-lg font-medium mt-20">Cargando...</div>;
  }

  if (error) {
    return (
      <div className="bg-white dark:bg-gray-800 text-center p-6 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2 mx-auto mt-20">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-2">
          Artículo no encontrado
        </h2>
        <p className="text-gray-500 dark:text-gray-400">{error}</p>
      </div>
    );
  }

  const portada = article.imagenes?.find((img) => img.is_portada);

  return (
    <div className="mx-auto mt-16 mb-24 bg-white dark:bg-gray-900 shadow-lg rounded-lg overflow-hidden font-normal w-screen text-left">
      {/* Imagen destacada */}
      <div className="relative w-full h-96">
        {portada ? (
          <img
            src={portada.url}
            alt={article.noticia?.titulo_principal}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-300 flex items-center justify-center text-gray-600 text-xl">
            Sin imagen destacada
          </div>
        )}
        <div className="absolute inset-0 bg-black bg-opacity-30 flex items-end p-6">
          <h1 className="text-4xl font-bold text-white drop-shadow-lg">
            {article.noticia?.titulo_principal}
          </h1>
        </div>
      </div>

      {/* Contenido */}
      <div className="p-8">
        {/* Información secundaria */}
        <div className="mb-6">
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Publicado el {new Date(article.noticia?.fecha_publicacion).toLocaleDateString()} | Autor:{" "}
            <span className="font-semibold">{article.noticia?.autor || "Desconocido"}</span>
          </p>
        </div>

        {/* Renderizado dinámico del contenido */}
        <div className="text-gray-800 dark:text-gray-300 text-lg leading-relaxed whitespace-pre-line pr-24">
          {article.contenido?.map((contenido, index) => {
            switch (contenido.tipo) {
              case 'h1':
                return (
                  <h1 key={index} className="text-2xl font-bold mb-4">
                    {contenido.contenido}
                  </h1>
                );
              case 'h2':
                return (
                  <h2 key={index} className="text-xl font-semibold mb-3">
                    {contenido.contenido}
                  </h2>
                );
              case 'texto':
                return (
                  <p key={index} className="mb-4">
                    {contenido.contenido}
                  </p>
                );
              case 'imagen':
                return (
                  <div key={index} className="mt-10 mb-4 flex justify-center">
                    <img
                      src={contenido.contenido}
                      alt="Descripción de la imagen"
                      className="max-w-2xl h-auto"
                    />
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default ArticleDetail;
