import IMGFelipe from '../images/nosotros/IMG_2346.JPG'
import IMGNicolasChinchon from '../images/nosotros/NicolasChinchon.jpeg'
import IMGNicolas from '../images/nosotros/IMG_2348.JPG'
import IMGPablo from '../images/nosotros/IMG_2349.JPG'
import IMGBenjamin from '../images/nosotros/Benjamin.jpeg'
import IMGDiego from '../images/nosotros/Diego.jpeg'

const Equipo = () => {
    return(
        <>
            <div className="lg:w-1/2 mt-8 p-4 lg:p-8 flex bg-white dark:bg-[#0c0c0c] h-auto">
                <div className="flex-1 flex justify-center">
                    <h1 className="text-4xl font-bold bg-blue-500 px-3 py-1 rounded-md text-white">
                        Nuestro Equipo
                    </h1>
                </div>
            </div>


            <div className="space-y-24 mt-10 max-w-7xl mx-auto px-10 mb-24">
                <div className="relative flex flex-col md:flex-row items-center">
                    <div className="-mb-24 relative md:mb-0 md:absolute md:left-24 md:transform md:-translate-x-1/2">
                        <img
                            src={IMGFelipe}
                            alt="Perfil"
                            className="w-48 h-48 rounded-full border-8 bg-slate-50 border-slate-50 dark:bg-black dark:border-black"
                        />
                    </div>

                    <div className="md:ml-28 bg-[#f7f9ff] dark:bg-black p-6 rounded-lg flex flex-col pt-32 md:p-6 md:flex-row md:items-center w-full">
                    <div className="md:ml-20 basis-[35%] text-center md:text-start">
                        <span className="bg-blue-500 text-white text-xs px-2 py-0.5 rounded-md mr-2">
                            CEO & Fundador
                        </span>
                        <h2 className="text-2xl md:text-3xl font-bold text-gray-800 dark:text-white">Felipe Millar</h2>
                        <p className="text-black text-base dark:text-white">fmillar@qrtsolutions.com</p>
                    </div>

                    <div className="border-t md:border-t-0 md:border-l border-gray-200 pt-6 md:pt-0 md:pl-6 mt-6 md:mt-0 basis-[65%]">
                        <p className="text-black text-sm dark:text-slate-400">
                            Felipe es el núcleo visionario de QRT. Como CEO, combina una profunda comprensión de la inteligencia de datos y la visión estratégica para liderar el rumbo de la empresa. Su enfoque en la innovación y el liderazgo le permite conectar a cada miembro, potenciando el impacto colectivo.
                        </p>
                    </div>
                </div>


                </div>

                <div className="relative flex flex-col md:flex-row items-center">
                    <div className="-mb-24 relative md:mb-0 md:absolute md:left-24 md:transform md:-translate-x-1/2">
                        <img
                            src={IMGNicolasChinchon}
                            alt="Perfil"
                            className="w-48 h-48 rounded-full border-8 bg-slate-50 border-slate-50 dark:bg-black dark:border-black"
                            />
                    </div>

                    <div className="md:ml-28 bg-[#f7f9ff] dark:bg-black p-6 rounded-lg flex flex-col pt-32 md:p-6 md:flex-row md:items-center w-full">
                        <div className="md:ml-20 basis-[35%] text-center md:text-start">
                            <span className="bg-blue-500 text-white text-xs px-2 py-0.5 rounded-md mr-2">
                                Gerente General (COO)
                            </span>
                            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 dark:text-white">Nicolás Chinchon</h2>
                            <p className="text-black text-base dark:text-white">nchinchon@qrtsolutions.com</p>
                        </div>

                        <div className="border-t md:border-t-0 md:border-l border-gray-200 pt-6 md:pt-0 md:pl-6 mt-6 md:mt-0 basis-[65%]">
                            <p className="text-black text-sm dark:text-slate-400">
                            Como COO, Nicolás coordina los flujos operativos de QRT. Su capacidad para optimizar procesos y su visión organizacional logran que cada proyecto avance con precisión y eficiencia, asegurando que cada nodo del equipo funcione en perfecta armonía.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="relative flex flex-col md:flex-row items-center">
                    <div className="-mb-24 relative md:mb-0 md:absolute md:left-24 md:transform md:-translate-x-1/2">
                        <img
                            src={IMGPablo}
                            alt="Perfil"
                            className="w-48 h-48 rounded-full border-8 bg-slate-50 border-slate-50 dark:bg-black dark:border-black"
                            />
                    </div>

                    <div className="md:ml-28 bg-[#f7f9ff] dark:bg-black p-6 rounded-lg flex flex-col pt-32 md:p-6 md:flex-row md:items-center w-full">
                        <div className="md:ml-20 basis-[35%] text-center md:text-start">
                            <span className="bg-blue-500 text-white text-xs px-2 py-0.5 rounded-md mr-2">
                                Líder Técnico (CTO)
                            </span>
                            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 dark:text-white">Pablo Acevedo</h2>
                            <p className="text-black text-base dark:text-white">pacevedo@qrtsolutions.com</p>
                        </div>

                        <div className="border-t md:border-t-0 md:border-l border-gray-200 pt-6 md:pt-0 md:pl-6 mt-6 md:mt-0 basis-[65%]">
                            <p className="text-black text-sm dark:text-slate-400">
                            Pablo es el arquitecto técnico que sustenta la infraestructura digital de QRT. Su expertise en soluciones Cloud y MLOps permite que la tecnología de QRT evolucione y se adapte a los desafíos de cada cliente, haciendo que el sistema funcione con fluidez y potencia.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="relative flex flex-col md:flex-row items-center">
                    <div className="-mb-24 relative md:mb-0 md:absolute md:left-24 md:transform md:-translate-x-1/2">
                        <img
                            src={IMGDiego}
                            alt="Perfil"
                            className="w-48 h-48 rounded-full border-8 bg-slate-50 border-slate-50 dark:bg-black dark:border-black"
                            />
                    </div>

                    <div className="md:ml-28 bg-[#f7f9ff] dark:bg-black p-6 rounded-lg flex flex-col pt-32 md:p-6 md:flex-row md:items-center w-full">
                        <div className="md:ml-20 basis-[35%] text-center md:text-start">
                            <span className="bg-blue-500 text-white text-xs px-2 py-0.5 rounded-md mr-2">
                                Jefe Comercial
                            </span>
                            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 dark:text-white">Diego Nehgme</h2>
                            <p className="text-black text-base dark:text-white">dnehgme@qrtsolutions.com</p>
                        </div>

                        <div className="border-t md:border-t-0 md:border-l border-gray-200 pt-6 md:pt-0 md:pl-6 mt-6 md:mt-0 basis-[65%]">
                            <p className="text-black text-sm dark:text-slate-400">
                            Diego es el enlace entre QRT y sus clientes. Con una habilidad excepcional para identificar oportunidades de negocio y una comprensión profunda de las necesidades del mercado, facilita que las soluciones de QRT sean accesibles y transformadoras para cada cliente.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="relative flex flex-col md:flex-row items-center">
                    <div className="-mb-24 relative md:mb-0 md:absolute md:left-24 md:transform md:-translate-x-1/2">
                        <img
                            src={IMGNicolas}
                            alt="Perfil"
                            className="w-48 h-48 rounded-full border-8 bg-slate-50 border-slate-50 dark:bg-black dark:border-black"
                            />
                    </div>

                    <div className="md:ml-28 bg-[#f7f9ff] dark:bg-black p-6 rounded-lg flex flex-col pt-32 md:p-6 md:flex-row md:items-center w-full">
                        <div className="md:ml-20 basis-[35%] text-center md:text-start">
                            <span className="bg-blue-500 text-white text-xs px-2 py-0.5 rounded-md mr-2">
                                Analista de Inteligencia de Negocios
                            </span>
                            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 dark:text-white">Nicolás Meléndez</h2>
                            <p className="text-black text-base dark:text-white">nmelendez@qrtsolutions.com</p>
                        </div>

                        <div className="border-t md:border-t-0 md:border-l border-gray-200 pt-6 md:pt-0 md:pl-6 mt-6 md:mt-0 basis-[65%]">
                            <p className="text-black text-sm dark:text-slate-400">
                            Nicolás convierte datos en insights accionables. Su habilidad para diseñar dashboards y extraer valor de la información convierte los datos en una herramienta estratégica, permitiendo que QRT y sus clientes tomen decisiones informadas y precisas.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="relative flex flex-col md:flex-row items-center">
                    <div className="-mb-24 relative md:mb-0 md:absolute md:left-24 md:transform md:-translate-x-1/2">
                        <img
                            src={IMGBenjamin}
                            alt="Perfil"
                            className="w-48 h-48 rounded-full border-8 bg-slate-50 border-slate-50 dark:bg-black dark:border-black"
                            />
                    </div>

                    <div className="md:ml-28 bg-[#f7f9ff] dark:bg-black p-6 rounded-lg flex flex-col pt-32 md:p-6 md:flex-row md:items-center w-full">
                        <div className="md:ml-20 basis-[35%] text-center md:text-start">
                            <span className="bg-blue-500 text-white text-xs px-2 py-0.5 rounded-md mr-2">
                                Gerente de Proyectos (CFO)
                            </span>
                            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 dark:text-white">Benjamín Aristegui</h2>
                            <p className="text-black text-base dark:text-white">baristegui@qrtsolutions.com</p>
                        </div>

                        <div className="border-t md:border-t-0 md:border-l border-gray-200 pt-6 md:pt-0 md:pl-6 mt-6 md:mt-0 basis-[65%]">
                            <p className="text-black text-sm dark:text-slate-400">
                            Como CFO y gerente de proyectos, Benjamín asegura que los recursos financieros y estratégicos estén alineados con el crecimiento de QRT. Su visión financiera y de gestión es el soporte clave que permite al equipo explorar y optimizar nuevas posibilidades.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Equipo;