import React from 'react'
import sixbellLogo from '../images/nosotros/sixbell-logo.avif';
import BHPLogo from '../images/nosotros/BHP_2017_logo.svg.png';
import CheilWorldwideLogo from '../images/nosotros/Cheil_Worldwide_logo.svg.png';
import amsaLogo from '../images/nosotros/logo-amsa.png';
import Logoamsa from '../images/nosotros/amsa-logo.png';
import chileCompraLogo from '../images/nosotros/logo-chilecompra-original.png';
import MGRecursoLogo from '../images/nosotros/MGRecurso-8@4x1.png';
import patrimoreLogo from '../images/nosotros/patrimore-newlogo.webp';
import pepperstone from '../images/nosotros/PepperstoneLogo.png'

const Clients = ({isDarkMode}) => {
    const logos = [
        isDarkMode ? Logoamsa : amsaLogo,
        BHPLogo,
        CheilWorldwideLogo,
        chileCompraLogo,
        MGRecursoLogo,
        patrimoreLogo,
        sixbellLogo,
        pepperstone,
    ];
  return (
    <div>
             <div className="max-w-full mx-auto p-5 overflow-hidden mb-10">
            <div className="scroll-container whitespace-nowrap w-1/2 py-8">
                {[...logos, ...logos].map((logo, index) => (
                    <img
                        key={index}
                        src={logo}
                        alt={`Cliente ${index}`}
                        className="h-16 mx-8 mx-4 inline-block dark:opacity-100 opacity-90"
                    />
                ))}
            </div>
        </div>

        <style jsx>{`
            .scroll-container {
                display: inline-flex;
                animation: scroll-left 20s linear infinite;
            }

            @keyframes scroll-left {
                0% {
                    transform: translateX(0%);
                }
                100% {
                    transform: translateX(-100%);
                }
            }
        `}</style>
    </div>
  )
}

export default Clients
